import type { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import Toggle, { ToggleProps } from '@amzn/awsui-components-react/polaris/toggle';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { NAV_BAR_ID } from '../../common/constants';
import logo from '../../images/delonixLogo.svg';

export interface TopNavigationBarProps {
  onToggle: (event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>) => void;
  checked: boolean;
}

const TopNavigationBar = (props: TopNavigationBarProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <TopNavigation
      id={NAV_BAR_ID}
      identity={{
        href: '/mainAppAuthorised',
        title: 'Asset Collector',
        logo: {
          src: logo,
          alt: 'Delonix',
        },
      }}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
      }}
      utilities={[
        {
          type: 'button',
          text: 'Submit your feedback',
          href: 'https://pulse.aws/survey/PB6O2XRU',
          external: true,
        },
        {
          type: 'menu-dropdown',
          text: 'Help',
          items: [
            {
              id: 'slack',
              text: '#asset-collector-interest Slack channel',
              href: 'https://amazon.enterprise.slack.com/archives/C05KSF1GVPA',
              external: true,
            },
            {
              id: 'tt',
              text: 'Report an issue',
              href: 'https://tiny.amazon.com/12xyc6i5u/tcorpamazcreatemp8817',
              external: true,
            },
            {
              id: 'wiki',
              text: 'Wiki',
              href: 'https://w.amazon.com/bin/view/AssetCollector/TrainingAndTroubleshooting/QuickCreateAndUpdate/',
              external: true,
            },
            {
              id: 'error-guide',
              text: 'Error Guide',
              href: 'https://w.amazon.com/bin/view/AssetCollector/ErrorGuide',
              external: true,
            }
          ],
        },
      ]}
      search={
        <Toggle
          onChange={props.onToggle}
          checked={props.checked}
        >
          {props.checked ? 'Switch to light mode' : 'Switch to dark mode'}
        </Toggle>
      }
    />
  );
};

export default memo(TopNavigationBar);
